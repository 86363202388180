import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import styled, { createGlobalStyle, keyframes } from "styled-components"
import { Footer } from "./Footer"

import NavigationMenu from "./navigation"

import "./layout.css"

const GlobalStyle = createGlobalStyle`
  body {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.8em;
  }

  main {
    min-height: 70vh;
  }

  h1 {
    font-size: 52px;
    line-height: 52px;
  }

  h2 {
    font-size: 40px;
    line-height: 40px;
  }

  h3 {
    font-size: 26px;
    line-height: 26px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  button {
    font-family: 'Montserrat', cursive, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
      font-weight: 300;
      text-transform: uppercase;
  }

  @media (max-width: 600px) {
    h1 {
      font-size: 52px;
      line-height: 52px;
    }

    h2 {
      font-size: 34px;
      line-height: 34px;
    }

    h3 {
      font-size: 24px;
      line-height: 24px;
    }
  }

  @media (max-width: 395px) {
    h1 {
      font-size: 38px;
      line-height: 38px;
    }

    h2 {
      font-size: 28px;
      line-height: 28px;
    }

    h3 {
      font-size: 20px;
      line-height: 20px;
    }
  }
`

const Layout = ({ children }) => {
  const { sanityNavigationMenu, site } = useStaticQuery(graphql`
    {
      sanityNavigationMenu {
        items {
          title
          slug {
            current
          }
        }
      }
      site {
        siteMetadata {
          title
          netlifyDeployContext
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Abril+Fatface|Montserrat:300,400,500"
          rel="stylesheet"
        />
        {site.siteMetadata.netlifyDeployContext !== "production" && (
          <meta name="robots" content="noindex,nofollow" />
        )}
      </Helmet>
      <GlobalStyle />
      <EnterAnimation>
        {sanityNavigationMenu && sanityNavigationMenu.items && (
          <NavigationMenu
            menuItems={sanityNavigationMenu.items.map(i => ({
              href: `/${i.slug.current}`,
              title: i.title,
            }))}
          />
        )}
        <main>{children}</main>
        <Footer />
      </EnterAnimation>
    </>
  )
}

const fadeIn = keyframes`
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
`

const EnterAnimation = styled.div`
  background-color: white;
  animation: ${fadeIn} 0.7s ease-in;
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
