import { Just, Maybe, Nothing } from "purify-ts"

type RouteReference = {
  _ref: string
  type: "reference"
}

export type CtaDto = {
  useCta: boolean
  _type: "ctaObject"
  cta: {
    externalLink?: string
    internalPath?: string
    routeReference?: RouteReference
    title: string
    _type: "cta"
  }
}

const privateConstruction = Symbol()

export type Cta =
  | {
      type: "EXTERNAL"
      externalLink: string
      title: string
      [privateConstruction]: true
    }
  | {
      type: "INTERNAL"
      internalLink: string
      title: string
      [privateConstruction]: true
    }
  | {
      type: "REFERENCE"
      routeReference: string
      [privateConstruction]: true
    }

export const getCta = (dto: CtaDto): Maybe<Cta> => {
  if (dto?.cta == null) return Nothing

  if (dto.cta.routeReference != null) {
    return Just({
      type: "REFERENCE",
      routeReference: dto.cta.routeReference._ref,
      [privateConstruction]: true,
    })
  }

  if (dto.cta.internalPath != null) {
    return Just({
      type: "INTERNAL",
      internalLink: dto.cta.internalPath,
      title: dto.cta.title,
      [privateConstruction]: true,
    })
  }

  if (dto.cta.externalLink != null) {
    return Just({
      type: "EXTERNAL",
      externalLink: dto.cta.externalLink,
      title: dto.cta.title,
      [privateConstruction]: true,
    })
  }

  return Nothing
}
