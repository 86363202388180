import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import Img from "gatsby-image/withIEPolyfill"
import { getFluid } from "../utils/imageHelpers"
import { FrontPageElements } from "../pages"
import { Button, ExternalButton } from "./Button"
import styled from "styled-components"
import { CtaDto, getCta } from "../modules/cta"
import {
  getFutureConcerts,
  getPastConcerts,
  useConcertsState,
} from "../modules/concert"
import { Concert } from "./Concert"
import { InstagramMedia } from "./InstagramMedia"

type SerializerProps = {
  children: any[]
  isInline: boolean | undefined
  options: {
    imageOptions: any
  }
}

type ImageWithAlt = {
  alt: string
  caption: string
  _type: "imageWithAlt"
  asset: {
    _ref: string
    _type: string
  }
}

type CommonPortableText = {
  _key: string
  textAlignment: "start" | "center" | "end"
  bodyPortableText: any
  cta?: CtaDto
}

type BodyPortableText = {
  _type: "bodyPortableTextObject"
} & CommonPortableText

type BodyPortableTextWithRightImage = {
  _type: "bodyPortableTextWithRightImageObject"
  image: ImageWithAlt
  imageWidthPercentage: number
} & CommonPortableText

type BodyPortableTextWithLeftImage = {
  _type: "bodyPortableTextWithLeftImageObject"
  image: ImageWithAlt
  imageWidthPercentage: number
} & CommonPortableText

type SectionElement =
  | BodyPortableText
  | BodyPortableTextWithRightImage
  | BodyPortableTextWithLeftImage

export type Section = {
  _key: string
  _type: "section"
  content: SectionElement[]
}

type SectionProps = {
  node: Section
} & SerializerProps

type BodyPortableTextProps = {
  node: BodyPortableText
} & SerializerProps

type BodyPortableTextWithRightImageProps = {
  node: BodyPortableTextWithRightImage
} & SerializerProps

type BodyPortableTextWithLeftImageProps = {
  node: BodyPortableTextWithLeftImage
} & SerializerProps

export const Section: React.FC<SectionProps> = props => (
  <StyledSection>
    <div className="section-top-margin" />
    <div className="section-outer-container">
      <div className="section-container">
        <BlockContent blocks={props.node.content} serializers={serializers} />
      </div>
    </div>
  </StyledSection>
)

const StyledSection = styled.div`
  & .section-top-margin {
    width: 50px;
    height: 12vw;
    max-height: 130px;
  }

  & .section-outer-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  & .section-container {
    width: 80%;
    max-width: 840px;
  }
`

const StyledImageRightWrapper = styled.div`
  margin-top: 40px;
  display: flex;

  @media (max-width: 820px) {
    flex-wrap: wrap-reverse;
  }

  & .gatsby-image-wrapper img {
    object-fit: contain !important;
  }

  & .gatsby-image-wrapper {
    flex: 0 0 ${({ imageWidth }: { imageWidth: number }) => imageWidth}%;
    margin-left: 50px;

    @media (max-width: 820px) {
      flex: 0 0 80%;
      margin-left: 0px;
      margin-bottom: 20px;
    }
  }
`

const StyledImageLeftWrapper = styled.div`
  margin-top: 40px;
  display: flex;

  @media (max-width: 820px) {
    flex-wrap: wrap;
  }

  & .gatsby-image-wrapper img {
    object-fit: contain !important;
  }

  & .gatsby-image-wrapper {
    flex: 0 0 ${({ imageWidth }: { imageWidth: number }) => imageWidth}%;
    margin-right: 50px;

    @media (max-width: 820px) {
      flex: 0 0 80%;
      margin-right: 0px;
      margin-bottom: 20px;
    }
  }
`

const RouteReferenceLink = ({ routeReference }: { routeReference: string }) => {
  const data = useStaticQuery(graphql`
    {
      allSanityRoute {
        edges {
          node {
            slug {
              current
            }
            title
            _id
          }
        }
      }
    }
  `)

  const link = data?.allSanityRoute?.edges?.filter(
    edge => edge?.node?._id == routeReference
  )?.[0]

  if (link?.node?.slug?.current == null) return null

  return <Button to={link.node.slug.current}>{link.node.title}</Button>
}

const TextWithButton = (
  props:
    | BodyPortableTextProps
    | BodyPortableTextWithRightImageProps
    | BodyPortableTextWithLeftImageProps
) => {
  const cta = getCta(props.node.cta)

  return (
    <div>
      <BlockContent
        blocks={(props.node && props.node.bodyPortableText) || []}
      />
      {cta.mapOrDefault(c => {
        switch (c.type) {
          case "EXTERNAL":
            return (
              <ExternalButton
                href={c.externalLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {c.title}
              </ExternalButton>
            )
          case "INTERNAL":
            return <Button to={c.internalLink}>{c.title}</Button>
          case "REFERENCE":
            return <RouteReferenceLink routeReference={c.routeReference} />
        }
      }, null)}
    </div>
  )
}

const serializers = {
  types: {
    section: (props: SectionProps) => {
      return <Section {...props} />
    },

    bodyPortableTextObject: (props: BodyPortableTextProps) => {
      return (
        <div
          style={{
            textAlign: (props.node && props.node.textAlignment) || "start",
          }}
        >
          <TextWithButton {...props} />
        </div>
      )
    },

    bodyPortableTextWithRightImageObject: (
      props: BodyPortableTextWithRightImageProps
    ) => {
      if (props?.node?.image?.asset == null) return null

      const fluid = getFluid(props.node.image, {
        maxHeight: 3500,
        maxWidth: 3500,
        quality: 70,
      })

      return (
        <StyledImageRightWrapper
          imageWidth={props?.node?.imageWidthPercentage ?? 40}
        >
          <TextWithButton {...props} />
          <Img fluid={fluid} alt={props.node.image.alt} />
        </StyledImageRightWrapper>
      )
    },

    bodyPortableTextWithLeftImageObject: (
      props: BodyPortableTextWithLeftImageProps
    ) => {
      if (props?.node?.image?.asset == null) return null

      const fluid = getFluid(props.node.image, {
        maxHeight: 3500,
        maxWidth: 3500,
        quality: 70,
      })

      return (
        <StyledImageLeftWrapper
          imageWidth={props?.node?.imageWidthPercentage ?? 40}
        >
          <Img fluid={fluid} alt={props.node.image.alt} />
          <TextWithButton {...props} />
        </StyledImageLeftWrapper>
      )
    },

    nextConcertsObject: () => {
      const concertsState = useConcertsState()

      if (concertsState.type !== "SUCCESS") return null

      const futureConcerts = getFutureConcerts(concertsState.concerts)

      return (
        <StyledNextConcertsSection>
          <div className="section-outer-container">
            <div className="section-container">
              {futureConcerts.length == 0 && (
                <div>
                  <i>Vi har for øyeblikket ingen planlagte konserter.</i>
                </div>
              )}

              {concertsState.concerts.length > 0 &&
                futureConcerts.map(c => (
                  <Concert concert={c} key={c.title + c.location} />
                ))}
            </div>
          </div>
        </StyledNextConcertsSection>
      )
    },

    pastConcertsObject: () => {
      const concertsState = useConcertsState()

      if (concertsState.type !== "SUCCESS") return null

      return (
        <StyledNextConcertsSection>
          <div className="section-outer-container">
            <div className="section-container">
              {getPastConcerts(concertsState.concerts).map(c => (
                <Concert concert={c} key={c.title + c.location} />
              ))}
            </div>
          </div>
        </StyledNextConcertsSection>
      )
    },

    imageWithAlt: props => {
      if (!props.node || !props.node.asset) return null

      return (
        <Img
          fluid={getFluid(props.node, {
            maxHeight: 3500,
            maxWidth: 3500,
            quality: 70,
          })}
          alt={props.node.alt}
        />
      )
    },

    instagramImages: () => <InstagramMedia />,
  },
}

const StyledNextConcertsSection = styled.div`
  & .section-top-margin {
    width: 50px;
    height: 12vw;
    max-height: 130px;
  }

  & .section-outer-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  & .section-container {
    width: 80%;
    max-width: 840px;
  }
`

type Props = {
  blocks: FrontPageElements[]
}

export const Container: React.FC<Props> = ({ blocks }) => {
  return <BlockContent blocks={blocks} serializers={serializers} />
}
