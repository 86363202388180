import React from "react"
import styled from "styled-components"
import { FaInstagram, FaFacebookF } from "react-icons/fa"

const StyledSocialIcon = styled.a`
  padding: 15px;

  & svg {
    transform: scale(2);
  }

  & path {
    transition: fill 0.4s;
    fill: rgba(0, 0, 0, 0.8);
  }

  :hover {
    cursor: pointer;

    & path {
      fill: rgba(0, 0, 0, 0.57);
    }
  }
`

type Props = {
  facebookUrl: string
  instagramUrl: string
}

const SocialButtons: React.FC<Props> = ({ facebookUrl, instagramUrl }) => (
  <div>
    <StyledSocialIcon
      target="_blank"
      rel="noopener noreferrer"
      href={facebookUrl ?? "https://www.facebook.com/detvildekor/"}
    >
      <FaFacebookF />
    </StyledSocialIcon>
    <StyledSocialIcon
      target="_blank"
      rel="noopener noreferrer"
      href={instagramUrl ?? "https://www.instagram.com/detvildekor/"}
    >
      <FaInstagram />
    </StyledSocialIcon>
  </div>
)

export { SocialButtons }
