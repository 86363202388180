import React from "react"

export const Logo = () => (
  <svg
    width="21.96mm"
    height="16.55mm"
    version="1.1"
    viewBox="0 0 21.96 16.55"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      transform="matrix(.03174 0 0 -.03174 -11.265 47.865)"
      style={{ strokeWidth: "11.115" }}
    >
      <g transform="translate(529.96,1300.3)" style={{ strokeWidth: "11.115" }}>
        <path
          d="m0 0c-7.836-11.291-26.351-10.479-32.149-7.522 1.524 2.436 16.683 31.022 11.192 40.077-1.732 2.136-2.341 2.336-4.785 2.444-15.258 0.919-19.023-33.98-19.735-36.325-1.119 0.713-4.478 2.552-11.597 1.326 0 0-4.577-3.86 1.524-3.463 4.477-0.199 6.105-1.218 9.464-2.435-0.323-3.877-1.558-12.277-1.938-22.259-8.284-24.544-19.09-26.051-21.485-25.049-2.443 1.532-7.935 0.406-8.341 16.584 4.17-0.713 15.768 3.968 15.462 16.683-0.812 7.629-7.526 7.224-11.292 7.431-8.863 0-13.742-6.089-15.929-13.743-0.107-0.058-0.174-8e-3 -0.29-0.091-4.171-3.057-12.513-5.6-16.277-4.788 0 1.624 0.816 12.408-0.816 17.702-9.969 39.065-48.932 38.354-68.058 23.194 0 0 0.103-3.454 3.155-1.524 7.729 4.482 17.193 8.748 26.55 7.115 10.686-0.811 28.893-19.118 26.147-48.011-11.291-1.624-31.535-9.668-33.163-10.479 0.811 6.005 3.557 26.855 4.373 34.584 0.812 3.769-7.629 10.073-11.394 10.885 0-1.624-1.424-18.108-2.241-27.469-0.811-6.909-0.609-12.914 0.92-18.713 0.915-3.156 4.27-0.405-2.444-6.105-1.628-1.623-14.853-14.041 2.444-20.445 4.473-2.451 40.689-9.061 53.612 35.199 3.143-0.092 12.031 1.466 16.443 4.116-1.036-7.464 0.012-15.482 2.216-20.593 3.752-7.124 28.446-14.936 40.354 14.256 0.261-9.7 1.652-19.988 5.55-28.702 2.849-6.113 30.625-35.72 42.425 36.614 0 0 0.509 2.65-1.223 3.263-1.627 0.911-2.033-0.613-2.033-0.613-7.733-48.12-22.077-45.883-26.45-41.717-3.868 4.581-6.714 28.794-3.562 56.67 3.256-3.456 29.502-1.931 35.301 8.953 0 0 1.326 4.987-1.93 2.95m-164.67-60.836c-1.222 4.175 21.769 21.968 44.865 24.313-3.157-30.31-37.137-41.41-44.865-24.313m91.803 43.953c1.732 1.218 5.596 1.111 5.902-4.787-1.221-9.659-6.204-10.786-11.19-11.184 1.018 8.334 2.236 12.203 5.288 15.971m43.668 44.352s1.628 5.284 4.681 3.155c5.902-6.303-9.258-32.663-8.546-31.743 0.407 4.582 0.916 19.426 3.865 28.588"
          style={{ fill: "#231f20", strokeWidth: "11.115" }}
        />
      </g>
      <g transform="translate(1031.9,1037.8)" style={{ strokeWidth: "11.115" }}>
        <path
          d="m0 0c-1.624 1.11-2.336-0.108-2.336-0.108s-7.124-45.684-18.423-43.845c0 0-5.384 0.505-2.733 20.146 1.01 7.422 2.02 9.153 2.12 17.395 0.414 5.492-4.871 7.323-9.145 4.672-3.264-2.435-14.148-4.166-16.385 2.039 2.651 5.797 8.449 13.021 2.849 17.702-3.562 2.957-9.261 1.018-11.597-5.592-1.723-6.329 0.067-10.313 2.685-15.242-4.375-6.478-13.073-6.23-15.21-4.805 2.949 8.549 1.21 13.536-0.613 19.335-3.065 8.748-11.912 10.578-16.384 8.333-1.624-0.812-6.412-2.535-5.799-9.759-6.71 1.218-11.498-10.884-10.372-24.005 0.05-0.696 0.167-1.483 0.299-2.336-7.373-21.455-16.219-21.612-18.663-21.272-7.116 1.018-17.496 14.04-25.837 34.493 7.836 3.959 23.302 14.744 40.391 50.761 1.226 2.95-1.624 5.601-3.346 2.138-19.856-36.316-33.583-47.813-40.301-47.416 0.207 4.788 3.968 35.206-4.266 42.429-8.35 7.323-12.111 5.285-8.151-12.724 3.769-16.881-4.572-65.607-5.086-75.48-0.298-4.068-0.497-5.799 4.481-7.737 3.356-0.398 7.828-0.912 8.242 2.452 1.012 5.798 2.643 30.616 2.95 35.189 3.462-12.094 14.546-32.555 27.568-33.566 7.869-0.596 18.025 7.364 24.271 23.294 1.938-4.25 5.086-7.861 10.156-7.522 7.223 0.911 14.86 3.248 19.334 19.732 1.159-0.174 11.945-2.535 18.34 5.591 3.529-3.214 10.089-5.342 17.396-2.94-1.641-5.699-7.025-24.222-0.315-36.017 2.137-3.761 9.062-7.026 16.286-3.256 4.158 2.237 12.308 6.105 18.307 45.27 0 0 0.513 1.939-0.713 2.651m-83.036-24.934c-6.51 1.731-5.5 21.67-4.672 26.044 0.398 1.226-2.253 6.611-2.253 7.43 4.689-10.28 13.635-18.215 17.711-18.928-3.265-8.54-7.025-13.933-10.786-14.546m11.896 19.135c-6.097 2.842-13.32 12.202-13.32 18.406 0.612 7.937 7.124 5.394 9.658 2.039 1.839-2.751 6.113-8.036 3.662-20.445m20.477 20.03c2.949 3.463 4.473 0.108 4.473 0.108 0.812-2.949-0.91-4.481-3.661-10.885-1.939 2.642-2.651 7.017-0.812 10.777"
          style={{ fill: "#231f20", strokeWidth: "11.115" }}
        />
      </g>
      <g transform="translate(559.2,1189.9)" style={{ strokeWidth: "11.115" }}>
        <path
          d="m0 0s-8.739-7.207 1.579-21.223c0.554-2.311 17.896-3.669 19.222-1.333 1.784 2.634 0.621 9.137 1.163 13.733-1.922 6.885-16.459 8.632-21.964 8.823"
          style={{ fill: "#231f20", strokeWidth: "11.115" }}
        />
      </g>
      <g transform="translate(953.82,1183.7)" style={{ strokeWidth: "11.115" }}>
        <path
          d="m0 0c-10.719-62.235-35.918-69.36-41.841-67.81-6.105 2.725-18.348-1.64-24.519 35.338 9.799-0.281 34.898 14.223 30.061 43.307-4.325 17.239-19.607 14.132-28.314 13.378-20.195-2.857-29.423-18.216-32.041-36.316-0.431-0.115-0.729-0.223-0.729-0.223-7.223-78.645-18.506-111.66-24.172-115.32-8.516-5.501-19.55 18.67-28.578 90.963 7.33 20.568 13.916 34.584 21.719 99.022 3.488 30.973-6.875 87.368-37.301 48.295-4.382-7.762-27.916-24.885-17.123-121.46-16.732 2.153-33.209 2.442-46.132-10.571-5.74-5.749-7.741-22.929-5.893-41.063-5.683-62.359-22.702-84.932-31.847-86.407-14.802 0.291-28.321 97.665-29.576 109.87 29.461 37.492 45.655 118.36 22.486 139.12-9.796 8.616-46.667 11.747-53.095-52.253-1.751-13.171-2.488-49.843 2.457-91.791-0.415-0.058-0.708-0.125-0.708-0.125-0.535-114.11-40.81-112.2-43.565-82.588-2.294 24.693-4.1 47.532-3.388 71.206 0.63 5.576-2.895 13.643-14.848 19.094-5.335 2.336-12.674-2.998-13.382-9.765-0.638-5.559-0.398-106.74 27.104-110.93 29.299-3.321 49.942 21.43 53.943 79.374 3.736-19.623 8.855-39.397 15.888-57.596 5.649-13.022 13.043-18.15 21.831-24.776 6.309-4.581 40.508-5.534 51.915 72.449 4.141-12.509 10.421-22.888 19.052-26.707 3.893-2.303 30.766-12.069 41.179 27.03 4.787-10.297 8.407-45.958 26.971-54.308 22.763-8.11 38.022 0.298 48.857 90.035 2.054-7.29 4.738-13.842 7.82-18.903 11.862-16.435 80.733-26.93 91.7 65.574 0 0-0.033 3.561-1.424 5.036-0.879 1.06-4.507-0.174-4.507-0.174m-290.65 92.968c2.547 8.93 16.157 8.722 17.577-19.881 3.136-65.988-25.351-100.26-25.351-100.26-1.342 22.904-1.55 105.24 7.774 120.14m99.474-196.42c-17.064-0.514-39.844 84.834 9.493 82.771 1.209-8.632 7.356-36.796 9.129-54.424 0.48-5.169-7.72-27.999-18.622-28.347m37.525 212.92c1.873 5.267 17.752 9.186 14.571-47.474-7.438-65.334-15.498-85.753-15.498-85.753-1.061 4.133-11.689 82.646 0.927 133.23m92.321-95.031c3.579 3.347 12.484 4.357 15.102-9.079 0.323-22.557-10.744-26.756-22.06-29.283-0.364 19.45 1.194 28.72 6.958 38.362"
          style={{ fill: "#231f20", strokeWidth: "11.115" }}
        />
      </g>
      <g transform="translate(1046.4,1330.9)" style={{ strokeWidth: "11.115" }}>
        <path
          d="m0 0-9.435-16.617c-57.096-53.111-147.39-7.969-205.81 15.933-7.513 3.26-19.181 8.483-26.508 11.287 74.185 26.964 168.24-15.552 168.24-3.537l-13.158 3.537c-24.164 1.686-25.013 7.774-48.153 9.427l1.934-0.24c17.015 6.37 62.91 15.552 69.272 33.93l-28.47-15.74c-24.284-8.949-34.664-9.691-56.26-13.92-5.662 1.396-11.911 2.439-19.888 2.8-26.86 0-45.237 0.708-73.013-6.556-5.078 0-8.325-3.753-16.758-3.339-46.774 12.103-110.04 15.354-160.39-7.024-47.801-22.577-70.627-43.163-85.6-99.715l-3.442-11.072c-17.027-66.559-33.499-117.35-42.553-209.83 0 0-34.038 87.541-46.144 136.09-15.458 47.092-62.003 68.133-46.894 35.279 6.548-14.314 11.071-26.051 18.899-49.478 15.905-46.802 35.691-107.58 48.021-127.51 4.729-6.485 36.962-35.719 37.475-2.319 0.858 5.443 8.566 72.151 40.661 210.75 13.063 54.142 32.915 76.024 44.164 84.56 38.171 28.96 87.302 42.396 136.78 39.571 73.518-4.241 102.03-32.364 184.14-57.265 24.689-7.488 57.422-11.709 82.87-4.643 12.723 4.241 54.97 22.337 50.021 45.643"
          style={{ fill: "#231f20", strokeWidth: "11.115" }}
        />
      </g>
      <g transform="translate(776.35,1491.7)" style={{ strokeWidth: "11.115" }}>
        <path
          d="m0 0c4.746 2.369 10.284 2.767 14.636 4.747 3.559 1.582 3.559 2.766 6.723 5.533 5.538 5.145 17.006 7.514 24.126 5.145 19.777-5.932 16.924-15.839 31.644-31.644 1.979-4.747 10.284-5.931 14.637-8.707 9.096-5.533 18.195-11.862 26.897-18.191 5.182-3.537 10.906-7.91 14.787-13.212 8.005-5.881 15.225-13.859 21.21-20.411 10.678-11.473 21.753-21.363 32.828-31.643 4.353-4.349 8.309-9.494 13.448-13.056 4.354-3.165 9.494-4.349 14.24-6.726 8.226-4.109 25.957-13.263 31.644-18.987 0 0 2.618-1.001 0-3.553-2.618-2.56-7.12 1.184-8.702 2.369 1.582-2.767 6.573-5.351 3.562-7.911-3.015-2.551-9.1 3.164-12.264 6.329 2.373-3.165 9.344-8.913 3.956-11.075-5.385-2.154-21.824 12.549-37.973 25.315-16.149 12.765-30.061 16.219-46.277 24.13-6.184 2.701-8.991 4.639-15.014 3.984-1.006-0.422-2.473-0.745-4.129-0.994-1.516-0.48-2.788-0.828-4.2-0.497-3.632-0.348-7.228-0.546-8.698-0.911-3.537-0.936-7.074-1.549-10.62-1.971l-0.277-0.232c0.112 0.082 0.166 0.148 0.273 0.232l-0.236-0.025c-2.286-1.127-4.63-2.245-6.763-3.77-5.538-4.349-5.538-15.424-12.261-18.199-1.582-0.596-3.168-0.099-4.8 0.398-0.931-1.168-2-2.171-3.408-2.75-1.773-0.671-3.554 0-5.398 0.539-1.084-1.649-0.985-3.678 1.342-5.302-5.773-0.506-7.537 2.344-6.456 5.302-3.558-1.401-5.489-5.053-1.752-7.664-9.096-0.786-8.305 6.727-1.976 9.892 6.329 3.562 7.12 1.582 9.886 8.698 0.791 1.979 1.98 3.959 2.374 5.938 0.19 1.898-0.617 4.068-1.297 6.205-26.247 0.654-49.606 41.667-49.606 65.209 0 9.883 3.955 18.589 0.791 28.48-4.353 13.444-14.633 15.026-26.897 18.986"
          style={{ fill: "#231f20", strokeWidth: "11.115" }}
        />
      </g>
    </g>
  </svg>
)
