import React from "react"
import styled from "styled-components"
import sortWith from "ramda/src/sortWith"
import prop from "ramda/src/prop"
import descend from "ramda/src/descend"
import {
  InstagramMedia as InstagramMediaType,
  useInstagramMedia,
} from "../modules/instagram"

const StyledImages = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .images {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;

    & img {
      margin: 0 10px;
    }
  }

  & #large-images {
    display: none;

    @media (min-width: 1320px) {
      display: block;
    }
  }

  & #medium-images {
    display: none;

    @media (min-width: 1000px) and (max-width: 1319px) {
      display: block;
    }
  }

  & #small-images {
    display: none;

    @media (min-width: 541px) and (max-width: 999px) {
      display: block;
    }
  }

  & #smallest-images {
    display: none;

    & .images {
      flex-direction: column;
    }

    @media (max-width: 540px) {
      display: block;
    }
  }
`

const InstagramMedia = () => {
  const mediaState = useInstagramMedia()

  if (mediaState.type != "SUCCESS") return null

  const sortedMedia: InstagramMediaType[] = sortWith([
    descend(prop("timestamp")),
  ])(mediaState.data).slice(0, 8)

  const MediaItem = ({
    image,
    size,
  }: {
    image: InstagramMediaType
    size: number
  }) => (
    <a href={image.postUrl} target="_blank" rel="noopener noreferrer">
      <img
        src={image.imageUrl}
        key={image.id}
        width={size}
        height={size}
        title={image.caption}
      />
    </a>
  )

  return (
    <StyledImages>
      <div id="large-images">
        <div className="images">
          {sortedMedia.slice(0, 4).map(image => (
            <MediaItem image={image} key={image.id} size={300} />
          ))}
        </div>
        <div className="images">
          {sortedMedia.slice(4, 8).map(image => (
            <MediaItem image={image} key={image.id} size={300} />
          ))}
        </div>
      </div>
      <div id="medium-images">
        <div className="images">
          {sortedMedia.slice(0, 4).map(image => (
            <MediaItem image={image} key={image.id} size={240} />
          ))}
        </div>
        <div className="images">
          {sortedMedia.slice(4, 8).map(image => (
            <MediaItem image={image} key={image.id} size={240} />
          ))}
        </div>
      </div>
      <div id="small-images">
        <div className="images">
          {sortedMedia.slice(0, 2).map(image => (
            <MediaItem image={image} key={image.id} size={240} />
          ))}
        </div>
        <div className="images">
          {sortedMedia.slice(2, 4).map(image => (
            <MediaItem image={image} key={image.id} size={240} />
          ))}
        </div>
        <div className="images">
          {sortedMedia.slice(4, 6).map(image => (
            <MediaItem image={image} key={image.id} size={240} />
          ))}
        </div>
        <div className="images">
          {sortedMedia.slice(6, 8).map(image => (
            <MediaItem image={image} key={image.id} size={240} />
          ))}
        </div>
      </div>
      <div id="smallest-images">
        <div className="images">
          {sortedMedia.slice(0, 8).map(image => (
            <MediaItem image={image} key={image.id} size={260} />
          ))}
        </div>
      </div>
    </StyledImages>
  )
}

export { InstagramMedia }
