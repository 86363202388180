import React, { useState, useEffect } from "react"
import styled, { createGlobalStyle } from "styled-components"
import { MdMenu } from "react-icons/md"
import { MdClose } from "react-icons/md"
import Drawer from "@material-ui/core/Drawer"
import { withStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"
import { Logo } from "./logo"

const Wrapper = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.8);
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 13px;
  height: 100px;

  transform: translateY(${props => (props.menuVisible ? 0 : "-120px")});
  transition: transform ease-out 0.2s;

  @media (min-width: 1001px) {
    background-color: white;
  }

  .outer-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media (max-width: 1000px) {
      display: none;
    }
  }
`

const Links = styled.nav`
  display: flex;

  a {
    color: rgba(0, 0, 0, 0.8);
    text-decoration: none;
    padding: 10px;
    transition: background-color ease-out 0.2s, color ease-out 0.2s;

    @media (min-width: 1200px) {
      padding: 10px 20px;
    }
  }

  a:hover {
    background-color: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.86);
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    margin-top: 8vw;
    text-align: center;

    a {
      font-family: "Montserrat", san-serif;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 300;
      padding: 25px 20px;
      width: 100%;
    }
  }
`

const StyledMenu = styled(MdMenu)`
  position: absolute;
  right: 5vw;
  top: 5vw;
  transform: scale(2.2);
  background-color: rgba(255, 255, 255, 0.5);

  @media (min-width: 1001px) {
    display: none !important;
  }

  @media (max-width: 1000px) {
    display: inline-block !important;
  }

  &:hover {
    cursor: pointer;
  }
`

const StyledClose = styled(MdClose)`
  position: absolute;
  right: 5vw;
  top: 5vw;
  transform: scale(2.2);
  color: rgba(0, 0, 0, 0.8);

  &:hover {
    cursor: pointer;
  }
`

const styles = {
  paper: {
    backgroundColor: "white",
    height: "100%",
  },
}

const HtmlOverflowHidden = createGlobalStyle`
  html {
    overflow: hidden;
  }
`

const NavigationMenu = ({ classes, menuItems }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [prevScrollPosition, setPrevScrollPosition] = useState(0)
  const [beginScrolldown, setBeginScrolldown] = useState(null)
  const [menuVisible, setMenuVisible] = useState(true)

  const closeOnClick = () => setIsOpen(false)

  useEffect(() => {
    const onScroll = () => {
      const currentScrollPosition = window.pageYOffset

      if (
        prevScrollPosition < currentScrollPosition &&
        beginScrolldown === null
      ) {
        setBeginScrolldown(currentScrollPosition)
      } else if (prevScrollPosition > currentScrollPosition) {
        setBeginScrolldown(null)
      }

      setMenuVisible(
        !(beginScrolldown && beginScrolldown < currentScrollPosition - 50) ||
          currentScrollPosition < 300
      )
      setPrevScrollPosition(currentScrollPosition)
    }

    window.addEventListener("scroll", onScroll)

    return () => window.removeEventListener("scroll", onScroll)
  })

  return (
    <Wrapper menuVisible={menuVisible}>
      {isOpen && <HtmlOverflowHidden />}
      <StyledMenu onClick={() => setIsOpen(true)} />
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        classes={classes}
      >
        <div style={{ width: "80vw", maxWidth: "300px", position: "relative" }}>
          <StyledClose onClick={closeOnClick} />
          <Links>
            {[{ href: "/", title: "Hjem" }]
              .concat(menuItems)
              .concat([{ href: "/kontakt", title: "Kontakt" }])
              .map(item => (
                <Link
                  key={item.href}
                  to={`${item.href}`}
                  onClick={closeOnClick}
                >
                  {item.title}
                </Link>
              ))}
          </Links>
        </div>
      </Drawer>
      <div className="outer-links" style={{ width: "70%", maxWidth: "1000px" }}>
        <Link to="/">
          <Logo />
        </Link>
        <Links>
          {[{ href: "/", title: "Hjem" }]
            .concat(menuItems)
            .concat([{ href: "/kontakt", title: "Kontakt" }])
            .map(item => (
              <Link key={item.href} to={`${item.href}`}>
                {item.title}
              </Link>
            ))}
        </Links>
      </div>
    </Wrapper>
  )
}

export default withStyles(styles)(NavigationMenu)
