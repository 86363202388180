import { getFluidGatsbyImage, getFixedGatsbyImage, GatsbyFluidImageProps, GatsbyFixedImageProps } from "gatsby-source-sanity"
import config from "../../sanity-config"

type Image = {
  asset: {
    _ref: string;
  };
  crop: any;
  hotspot: any;
};

export function buildImageObj(source) {
  const imageObj: Image = {
    asset: { 
      _ref: source.asset._ref || source.asset._id
     },
      crop: source.crop,
      hotspot: source.hotspot
  }

  return imageObj
}

export const getFluid = (image, options = {}): GatsbyFluidImageProps | null =>
  getFluidGatsbyImage(image.asset._ref, options, config)

export const getFixed = (image, options = {}): GatsbyFixedImageProps | null =>
  getFixedGatsbyImage(image.asset._ref, options, config)
