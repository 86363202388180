import React from "react"
import styled from "styled-components"
import { Concert as ConcertType, getWhenText } from "../modules/concert"
import Img from "gatsby-image/withIEPolyfill"
import BlockContent from "@sanity/block-content-to-react"

type Props = {
  concert: ConcertType
}

const Concert: React.FC<Props> = ({ concert }) => {
  const { date, time } = getWhenText(concert.date.local)

  return (
    <ConcertWrapper>
      <div className="concert-image-wrapper">
        <Img fluid={concert.image} alt="Konsert-bilde" />
      </div>
      <ConcertBody>
        <h2>{concert.title}</h2>
      </ConcertBody>
      <ConcertText>
        <WhereWhen>
          <h3>Når</h3>
          <div className="where-when-description">
            {date}
            <br />
            {time}
          </div>
          <h3>Hvor</h3>
          <div className="where-when-description">{concert.location}</div>
        </WhereWhen>
        <Description>
          <BlockContent blocks={concert.rawDescription} />
        </Description>
      </ConcertText>
    </ConcertWrapper>
  )
}

const ConcertWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 200px;

  & .gatsby-image-wrapper img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  & .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  & .concert-image-wrapper {
    padding-bottom: 56.25%;
    width: 100%;
    height: 0;
    overflow: hidden;
    position: relative;
  }
`

const ConcertBody = styled.div`
  /* margin-top: 20px; */
`

const ConcertText = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const WhereWhen = styled.div`
  flex: 1 1 20%;
  padding-right: 20px;
  margin-bottom: 26px;

  & .where-when-description {
    font-size: 13.5px;
    max-width: 105px;
  }

  & h3 {
    margin-bottom: 4px;
  }

  & h3:first-child {
    margin-top: 4px;
  }
`

const Description = styled.div`
  flex: 1 1 80%;
`

export { Concert }
