import { Codec, date, number, string, GetType, array, unknown } from "purify-ts"
import { sortWith, path, descend, ascend } from "ramda"
import { useState, useEffect } from "react"

const RichDateCodec = Codec.interface({
  local: date,
  offset: number,
  timezone: string,
  utc: date,
})

export type RichDate = GetType<typeof RichDateCodec>

const ImageCodec = Codec.interface({
  aspectRatio: number,
  sizes: string,
  src: string,
  srcSet: string,
  srcSetWebp: string,
  srcWebp: string,
})

type Image = GetType<typeof ImageCodec>

const ConcertCodec = Codec.interface({
  date: RichDateCodec,
  location: string,
  title: string,
  image: ImageCodec,
  rawDescription: unknown,
})

export type Concert = GetType<typeof ConcertCodec>

const ConcertFeedCodec = Codec.interface({
  items: array(ConcertCodec),
})

export type ConcertFeed = GetType<typeof ConcertFeedCodec>

export type ConcertsState =
  | { type: "NOT_ASKED" }
  | { type: "LOADING" }
  | { type: "SUCCESS"; concerts: Concert[] }
  | { type: "FAILURE"; error: Error }

const fetchConcerts = async () => await fetch("/concerts-1.json")

const getConcertsFromResponse = (feed: ConcertFeed): Concert[] => {
  const decoded = ConcertFeedCodec.decode(feed)

  return decoded.caseOf({
    Left: s => {
      // This is a cheap shortcut, but... Not like we're going to log to a backend, or display errors?
      console.error(s)
      return [] as Concert[]
    },
    Right: f => f.items,
  })
}

type When = {
  date: string
  time: string
}

export const getWhenText = (date: Date): When => {
  let month = ""

  switch (date.getMonth()) {
    case 0:
      month = "januar"
      break
    case 1:
      month = "februar"
      break
    case 2:
      month = "mars"
      break
    case 3:
      month = "april"
      break
    case 4:
      month = "mai"
      break
    case 5:
      month = "juni"
      break
    case 6:
      month = "juli"
      break
    case 7:
      month = "august"
      break
    case 8:
      month = "september"
      break
    case 9:
      month = "oktober"
      break
    case 10:
      month = "november"
      break
    case 11:
      month = "desember"
      break
  }

  const d = `${date.getDate()}. ${month} ${date.getFullYear()}`

  const t = `${formatTwoDigits(date.getHours())}:${formatTwoDigits(
    date.getMinutes()
  )}`

  return {
    date: d,
    time: t,
  }
}

const formatTwoDigits = (n: number): string =>
  n < 10 ? "0" + String(n) : String(n)

export const getFutureConcerts = (concerts: Concert[]) =>
  sortWith([ascend(path(["date", "local"]))])(
    concerts.filter(c => c.date.local >= new Date())
  )

export const getPastConcerts = (concerts: Concert[]) =>
  sortWith([descend(path(["date", "local"]))])(
    concerts.filter(c => c.date.local < new Date())
  )

export const useConcertsState = (): ConcertsState => {
  const [concertsState, setConcertsState] = useState<ConcertsState>({
    type: "NOT_ASKED",
  })

  useEffect(() => {
    const geddit = async () =>
      await fetchConcerts()
        .then(r => {
          if (!r.ok) {
            throw new Error(r.statusText)
          }

          return r.json()
        })
        .then(r =>
          setConcertsState({
            type: "SUCCESS",
            concerts: getConcertsFromResponse(r),
          })
        )
        .catch(e => {
          console.error(e)
          setConcertsState({ type: "FAILURE", error: e })
        })

    setConcertsState({ type: "LOADING" })
    geddit()
  }, [])

  return concertsState
}
