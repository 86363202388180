import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"

const buttonMixin = css`
  display: inline-block;
  border-width: 2px;
  border-radius: 50px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8);
  padding: 15px 60px;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.8);
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  width: fit-content;
  margin: 20px 0;
  text-decoration: none;
  transition: color ease-out 0.2s, background-color ease-out 0.2s;
  text-align: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
  }

  @media (max-width: 540px) {
    width: 100%;
    padding: 15px 30px;
  }
`

const Button = styled(Link)`
  ${buttonMixin};
`

const ExternalButton = styled.a`
  ${buttonMixin};
`

export { Button, ExternalButton, buttonMixin }
