import { useState, useEffect } from "react"

export type InstagramMedia = {
  id: string
  caption: string
  timestamp: Date
  imageUrl: string
  postUrl: string
}

export type InstagramImage = {
  type: "image"
} & InstagramMedia

export type InstagramVideo = {
  type: "video"
} & InstagramMedia

export type InstagramMediaState =
  | { type: "NOT_ASKED" }
  | { type: "LOADING" }
  | { type: "SUCCESS"; data: InstagramMedia[] }
  | { type: "FAILURE"; error: Error }

type RawMedia = {
  data: {
    user: {
      edge_owner_to_timeline_media: {
        edges: {
          node: {
            id: string
            __typename: string
            edge_media_to_caption: {
              edges: {
                node: {
                  text: string
                }
              }[]
            }
            shortcode: string
            taken_at_timestamp: number
            thumbnail_src: string
            is_video: boolean
          }
        }[]
      }
    }
  }
}

const getMediaFromResponse = (rawMedia: RawMedia): InstagramMedia[] => {
  try {
    const edges = rawMedia.data.user.edge_owner_to_timeline_media.edges

    return edges.map<InstagramMedia>(e => ({
      id: e.node.id,
      caption: e.node.edge_media_to_caption.edges?.[0]?.node?.text ?? "",
      timestamp: new Date(e.node.taken_at_timestamp * 1000),
      imageUrl: e.node.thumbnail_src,
      postUrl: `https://instagram.com/p/${e.node.shortcode}/`,
    }))
  } catch (error) {
    return [] as InstagramMedia[]
  }
}

export const useInstagramMedia = (): InstagramMediaState => {
  const [state, setState] = useState<InstagramMediaState>({
    type: "NOT_ASKED",
  })

  useEffect(() => {
    fetch(
      "https://www.instagram.com/graphql/query/?query_id=17888483320059182&variables=%7B%22id%22:%221128363758%22,%22first%22:10,%22after%22:null%7D"
    )
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText)
        }

        return response.json()
      })
      .then(r => {
        const media = getMediaFromResponse(r)

        if (media.length == 0) {
          setState({
            type: "FAILURE",
            error: new Error("No instagram media returned"),
          })
          return
        }

        setState({ type: "SUCCESS", data: media })
      })
  }, [])

  return state
}
