import React from "react"
import styled from "styled-components"
import { SocialButtons } from "./SocialButtons"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Container } from "../components/Container2"

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  max-width: none;
  height: 100vh;
  margin: 0px;
  text-align: center;
  font-size: 0.9em;
  color: rgba(0, 0, 0, 0.8) & .footer-inner {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    max-width: none;
    margin: 0px;
    height: auto;
    margin-bottom: 10px;
  }
`

const PrivacyPolicyLink = styled.div`
  margin-top: 26px;

  & a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.8);
  }

  & a:hover {
    text-decoration: underline;
  }
`

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      sanityFooter {
        facebookUrl
        instagramUrl
        _rawContent
      }
    }
  `)

  return (
    <StyledFooter id="kontakt-oss" className="container-component">
      <div className="footer-inner">
        {data?.sanityFooter?._rawContent != null && (
          <Container blocks={data?.sanityFooter?._rawContent} />
        )}
      </div>
      <SocialButtons
        facebookUrl={data?.sanityFooter?.facebookUrl}
        instagramUrl={data?.sanityFooter?.instagramUrl}
      />
      <div>
        <PrivacyPolicyLink>
          <Link to="/privacy-policy">Personvernerklæring</Link>
        </PrivacyPolicyLink>
      </div>
    </StyledFooter>
  )
}

export { Footer }
